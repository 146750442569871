import React, { useState } from "react";
import styles from '../css/navbar.module.scss';


const NavBar = (props) => {

    const [classToggle, setClassToggle] = useState(1); 

    return (
        <div style={{'position': 'fixed', 'width':'100%', 'zIndex': '998'}}>
            <div className={classToggle ? styles.topnav : [styles.responsive, styles.topnav].join(' ')} id="myTopnav">
                <a href="#" className={[styles.mainLink, styles.left].join(' ')}>Pods Blockchain, Inc.</a>
                <a href="#home" className={styles.active}>Home</a>
                <a href="#about" className={styles.link}>About</a>
                <a href="#contact">Contact</a>
                <a href="javascript:void(0);" className={styles.icon} onClick={() => { setClassToggle(!classToggle) }}>
                    <i className="fa fa-bars"></i>
                </a>
            </div>
        </div>
    );
}

export default NavBar;