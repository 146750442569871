import React, { useState } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { useForm } from "react-hook-form";
//Components
import NavBar from '../components/navbar';

//Scripts
import { GrowlComponent } from '@crystallize/react-growl';
import growl from '@crystallize/react-growl';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

//CSS
import '../css/main.css';

//Images
import midBanner from '../images/midbanner.jpg';
import iconDevelopment from '../images/icon_development.jpg';
import iconMobile from '../images/icon_mobile.jpg';
import iconSecurity from '../images/icon_security.jpg';
import iconEquity from '../images/icon_equity.jpg';


export default () => {

  const [cToken, setCToken] = useState('');

  const { register, handleSubmit, errors, reset, setError } = useForm();

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        address
      }
    }
  }
  `);

  const formProcessor = 'https://fywiplbe0b.execute-api.us-east-2.amazonaws.com/default/podsblockchainMailer';

  const onSubmitTwo = data => {
    console.log(data);
    return false;
  }



  const onSubmit = async data => {

    
    data = JSON.parse(JSON.stringify(data));
    console.log(data);
    let qString = new URLSearchParams(data).toString();
    console.log(qString);

    try {
      const response = await fetch(formProcessor+'?'+qString, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      });

      const json = await response.json();
      const statusCode = await response.status;

      if (statusCode != '200') {
        throw new Error(json);
      }

      reset();

      growl({
        message: <b>{json}</b>,
        type: 'success'
      });

    } catch (error) {

      growl({
        message: <b>{error.message}</b>,
        type: 'error'
      });

      setError('submit', 'submitError', error.message);
    }
  };


  return (

    <div className="landing">
      <div id="page-wrapper">

        <NavBar />

        <section id="banner">
          <h2>
            {data.site.siteMetadata.title}
          </h2>
          <p>Building applications that are better, easier, and more equitable</p>
          <ul className="actions">
            <li><a href="#contact" className="button special">Contact Us</a></li>
            <li><a href="#more" className="button">Learn More</a></li>
          </ul>
        </section>


        <section id="main" className="container">

          <section className="box special">
            <header className="major">
              <h2>Focused on developing applications
                    <br />
                that simplify the complex</h2>
              <p>Blockchain, cryptocurrencies, encryption, privacy. The technologies and <br />
                concerns of modern development can be perplexing. Our goal is to take these issues <br />
                and build them into applications that are accessible to everyone.</p>
            </header>
            <span className="image featured"><img src={midBanner} /></span>
          </section>

          <a name="about"></a>
          <section className="box special features">
            <div className="features-row">
              <section>
                <span className="icon major accent2"><img className="icon" src={iconDevelopment} /></span>
                <h3>Development</h3>
                <p>We strive to implement the newest technologies along with best coding practices in order to produce high-quality, reliable, and secure applications.</p>
              </section>
              <section>
                <span className="icon major accent3"><img className="icon" src={iconMobile} /></span>
                <h3>Mobile</h3>
                <p>Our core focus is on producing applications relying on the far-reach of mobile adoption, while keeping on the forefront of emerging technologies. </p>
              </section>
            </div>
            <div className="features-row">
              <section>
                <span className="icon major accent4"><img className="icon" src={iconSecurity} /></span>
                <h3>Security &amp; Privacy</h3>
                <p>At at time when when new security and user privacy breaches seem to make the headlines daily, we feel driven to protect the trust our users have put in our products.</p>
              </section>
              <section>
                <span className="icon major accent5"><img className="icon" src={iconEquity} /></span>
                <h3>Equity</h3>
                <p>Our goal is to make technology as accessible as possible. We deliver complex ideas in simple, intuitive, and easy to use forms, reaching audiences otherwise left out of advancements.</p>
              </section>
            </div>
          </section>
        </section>

        <a name="contact"></a>
        <section id="cta">

          <h2>Contact Us</h2>
          <p>Please use the form below to contact our corporate customer service.</p>


          <div>{errors && errors.submit && errors.submit.message}</div>


          <form name="form" onSubmit={handleSubmit(onSubmit)}>


            <div className="row uniform 50%">

              <div className="8u 12u(mobilep)">
                <input type="text" id="form_name" name="name" required="required" placeholder="Name" ref={register} />
              </div>
              <div className="8u 12u(mobilep)">
                <input type="email" id="form_email" name="email" required="required" placeholder="Email" ref={register} />
              </div>
              <div className="8u 12u(mobilep)">
                <textarea id="form_comment" name="comments" required="required" placeholder="Comment" ref={register}></textarea>
              </div>
              <div className="8u 12u(mobilep)" style={{ 'textAlign': 'center' }}>
                <button type="submit" id="form_send" className="fit, button">Send</button>
              </div>

            </div>

            <GoogleReCaptchaProvider
              reCaptchaKey="6Ld8RNQUAAAAAB5B9g5HT3V8b9XpU_q7wXXcmO8b" >
                <GoogleReCaptcha onVerify={token => {setCToken(token)}} />
                </GoogleReCaptchaProvider>


            <input type="hidden" id="form_captchaToken" name="captchaToken" ref={register} value={cToken} />
          </form>

        </section>


        <footer id="footer">
          <ul className="address">
            <li>Corporate Address:{data.site.siteMetadata.address}<br /><br /> </li>
          </ul>
          <ul className="copyright">
            <li>&copy; Pods Blockchain, Inc. All rights reserved.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
          </ul>
        </footer>

          <GrowlComponent />
      </div>
    </div>
  );
}